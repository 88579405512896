var popup = document.getElementById('pop-up');
var closePopup = document.getElementById('closePopup');
var overlay = document.getElementById('overlay');

document.addEventListener('DOMContentLoaded', function() {  
  
  document.querySelectorAll('.col-landing').forEach(function (showMore) {
    showMore.addEventListener('click', function() {
      

      popup.classList.add('visible');
      popup.classList.remove('fadeout');
      var telLink = popup.getElementsByTagName('a')[0];
      telLink.setAttribute('href', 'tel:' + showMore.getAttribute("data-tel"));
      telLink.innerHTML = showMore.getAttribute("data-tel");



    });
  });
  
  closePopup.addEventListener('click',function(){
    popup.classList.remove('visible');
    popup.classList.add('fadeout');

  });
});